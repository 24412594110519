<template>
    <a-layout :style="menuVisible ? 'overflow: hidden;': ''">
        <a-layout-header class="header">
            <div class="tab">
                <div class="tab-left">
                    <a href="https://www.maoxiubiji.com">
                        <img class="logo" src="../assets/img/login_logo.png" alt="猫嗅笔记" title="猫嗅笔记" />
                    </a>
                    <div class="tab-outer">
                        <div @click="clickHome" :class="['tab-item', currentRoute == '/' ? 'active' : '']">
                                首页
                        </div>
                        <!-- <router-link to="/vip">
                            <div :class="['tab-item', currentRoute == '/vip' ? 'active' : '']">
                                定价
                            </div>
                        </router-link> -->
                        <div @click="clickDownload" :class="['tab-item', currentRoute == '/download' ? 'active' : '']">
                            下载
                        </div>
                        <a href="https://www.yuque.com/chenpeng-x7zsz/tlbuso?#">
                            <div class="tab-item">
                                使用教程
                            </div>
                        </a>
                    </div>
                </div>
                <div class="btn-outer">
                    <div class="btn">
                        <a href="https://note.maoxiubiji.com">
                            <a-button v-if="isLogin" type="primary" size="large">进入猫嗅笔记</a-button>
                            <a-button v-else type="primary" size="large">登录/注册</a-button>
                        </a>
                    </div>
                    <div class="more">
                        <menu-outlined v-if="!menuVisible" @click="clickOpenMenu" :style="{ fontSize: '4.2vw' }" />
                        <close-outlined v-else @click="clickCloseMenu" :style="{ fontSize: '4.2vw' }" />
                    </div>
                </div>
            </div>
            <div :class="['header-navigation',menuVisible ? 'header-navigation-active' : '']">
                <div class="header-navigation-menus">
                    <div @click="clickHome" class="header-menuItem">
                        <span>首页</span>
                    </div>
                    <div @click="clickDownload" class="header-menuItem">
                        <span>下载</span>
                    </div>
                    <a href="https://www.yuque.com/chenpeng-x7zsz/tlbuso?#">
                        <div class="header-menuItem">
                            <span>使用教程</span>
                        </div>
                    </a>
                </div>
            </div>
        </a-layout-header>
        <a-layout-content style="margin-top:60px;">
            <router-view />
        </a-layout-content>
        <a-layout-footer style="background-color: #fff;padding: 0;">
            <div class="footer-1">
                <img class="logo" src="../assets/logo.png" />
                <div class="footer-1-1">
                    <span class="title">链接</span>
                    <router-link to="/download">下载</router-link>
                </div>

                <div class="footer-1-1">
                    <span class="title">服务支持</span>
                    <router-link target="_blank" to="/service_agreement">服务协议</router-link>
                    <router-link target="_blank" to="/privacy_agreement">隐私政策</router-link>
                </div>

                <div class="footer-1-1">
                    <span class="title">友情链接</span>
                    <a href="https://www.mao-xiu.com" target="_blank">云礼宝</a>
                    <a href="http://www.518card.com" target="_blank">猫嗅名片</a>
                </div>

                <div class="footer-1-1">
                    <span class="title">关于</span>
                    <router-link to="/about">关于我们</router-link>
                    <router-link to="/contact">联系我们</router-link>
                </div>

                <div class="footer-1-1" style="align-items: center;">
                    <img src="../assets/img/qrcode.jpg" />
                    <span style="margin-top: 10px;font-size: 14px;color: #666;">关注微信公众号</span>
                </div>
            </div>
            <div class="footer-2">
                <a-collapse expandIconPosition="right" :bordered="false" :ghost="true" v-model:activeKey="activeKey">
                    <a-collapse-panel key="1" header="链接" style="border: 0;">
                        <router-link to="/download">
                            <p style="margin: 0;text-align: left;margin-left: 4vw;font-size: 3.5vw;">下载</p>
                        </router-link>
                    </a-collapse-panel>
                    <a-collapse-panel key="2" header="服务支持" style="border: 0;">
                        <router-link to="/service_agreement">
                            <p style="margin-bottom: 4vw;;text-align: left;margin-left: 4vw;font-size: 3.5vw;">服务协议</p>
                        </router-link>
                        <router-link to="/privacy_agreement">
                            <p style="margin: 0;text-align: left;margin-left: 4vw;font-size: 3.5vw;">隐私政策</p>
                        </router-link>
                    </a-collapse-panel>
                    <a-collapse-panel key="3" header="友情链接" style="border: 0;">
                        <a href="https://www.mao-xiu.com">
                            <p style="margin-bottom: 4vw;;text-align: left;margin-left: 4vw;font-size: 3.5vw;">云礼宝</p>
                        </a>
                        <a href="http://www.518card.com">
                            <p style="margin: 0;text-align: left;margin-left: 4vw;font-size: 3.5vw;">猫嗅名片</p>
                        </a>
                    </a-collapse-panel>
                    <a-collapse-panel key="4" header="关于" style="border: 0;">
                        <router-link to="/about">
                            <p style="margin-bottom: 4vw;text-align: left;margin-left: 4vw;font-size: 3.5vw;">关于我们</p>
                        </router-link>
                        <router-link to="/contact">
                            <p style="margin: 0;text-align: left;margin-left: 4vw;font-size: 3.5vw;">联系我们</p>
                        </router-link>
                    </a-collapse-panel>
                </a-collapse>
            </div>
            <div class="footer-3">
                <p class="records">Copyright © 2016-{{$moment().format('YYYY')}} 义乌市猫嗅网络科技有限公司 All Rights Reserved 
                    <a style="color: #666;margin-left: 8px;" href="https://beian.miit.gov.cn/" target="_blank">浙ICP备16010149号-8</a> 
                    <a style="display: flex;color: #666;margin-left: 8px;" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33078202002117" target="_blank"><img style="width: 20px;height: 20px;" src="../assets/img/beianIcon.png" />浙公网安备 33078202002117号</a>
                </p>
            </div>
        </a-layout-footer>

        <iframe id="note-iframe" src="https://note.maoxiubiji.com/#/storageShare" style="display: none"></iframe>
    </a-layout>
</template>
<script>
import { defineComponent, ref, watch, onMounted } from 'vue';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons-vue';
import { useRouter } from 'vue-router';

export default defineComponent({
    components: {
        MenuOutlined,
        CloseOutlined
    },
    setup() {

        const isLogin = ref(false);

        onMounted(() => {
            const noteframe = document.getElementById('note-iframe');
            noteframe.onload = () => {
                noteframe.contentWindow.postMessage('website', 'https://note.maoxiubiji.com/#/storageShare');
            }
        })

        window.addEventListener('message', function (event) {
            if (event.origin === 'https://note.maoxiubiji.com') {
                isLogin.value = false;
                if (event.data.token) {
                    isLogin.value = true;
                }
            }
        }, false)

        const router = useRouter();

        const menuVisible = ref(false);

        const currentRoute = ref();
        const updateMenu = values => {
            currentRoute.value = router.currentRoute.value.path;
            menuVisible.value = false;
        }

        watch(() => updateMenu(), val => { });

        //打开菜单
        const clickOpenMenu = () => {
            menuVisible.value = true;
        }

        //关闭菜单
        const clickCloseMenu = () => {
            menuVisible.value = false;
        }

        const activeKey = ref(['0']);

        const clickHome = () => {
            clickCloseMenu();
            router.push('/');
        }

        const clickDownload = () => {
            clickCloseMenu();
            router.push('/download');
        }

        const clickAbout = () => {
            clickCloseMenu();
            router.push('/about');  
        }

        return {
            isLogin,

            currentRoute,

            menuVisible,
            clickOpenMenu,
            clickCloseMenu,

            activeKey,

            clickHome,
            clickDownload,
            clickAbout
        }
    }
})
</script>
<style lang="less" scoped>
a {
    color: #333;
}

.header {
    background: #fff;
    width: 100%;
    height: 60px;
    transform: translateX(-50%);
    z-index: 100;
    left: 50%;
    top: 0;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    line-height: 0
}

.tab {
    min-width: 1200px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tab-left {
        display: flex;
        align-items: center;

        .logo {
            width: 140px;
        }

        .tab-outer {
            margin-left: 100px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-width: 230px;

            .tab-item {
                position: relative;
                display: inline-block;
                font-size: 16px;
                font-weight: 500;
                line-height: 20px;
                color: #333;
                text-decoration: none;
            }

            .tab-item.active {
                color: @primary-color;
            }
        }
    }

    .btn-outer {

        .btn {
            display: block;
        }

        .more {
            display: none;
        }
    }
}

.header-navigation {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    background-color: #fff;
    flex-direction: column;
    left: 0;
    overflow-x: hidden;
    overflow-y: overlay;
    padding: 0;
    position: fixed;
    width: 100%;
    top: 12vw;
    height: 0;
    visibility: hidden;
    opacity: 0;

    .header-navigation-menus {
        display: flex;
        flex-wrap: nowrap;
        height: 100%;
        margin: 0;
        padding: 0;
        position: relative;
        flex: 1 0 auto;
        flex-direction: column;
        top: -0.26666667vw;
        width: 100%;
        padding-top: 4.26666667vw;

        .header-menuItem {
            cursor: pointer;
            margin: 0;
            padding: 0;
            position: relative;
            background-color: #fff;
            height: 12.8vw;
            width: 100%;
            font-size: 4.26666667vw;
            height: 12.8vw;
            line-height: 12.8vw;
            text-align: left;
            font-weight: 500;

            span {
                padding: 0 5.33333333vw;
            }
        }

        .header-menuItem:after {
            background-color: #eee;
            bottom: 0;
            content: "";
            display: block;
            height: 1px;
            opacity: 1;
            position: absolute;
            width: 100%;
        }
    }
}

.header-navigation-active{
    opacity: 1;
    height: calc(100% - 12vw);
    box-shadow: inset 0 0.53333333vw 1.06666667vw 0 rgba(0,0,0,.06);
    visibility: visible;
}

.footer-1 {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    padding-top: 50px;
    padding-bottom: 50px;
    position: relative;
    margin: 0 auto;

    .logo {
        width: 50px;
        height: 50px;
        margin-right: 50px;
    }

    .footer-1-1 {
        width: 240px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .title {
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            color: #333;
            margin-bottom: 25px;
        }

        a {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #666;
            margin-bottom: 15px;
            text-decoration: none;
        }

        img {
            width: 75px;
            height: 75px;
            border: 1px solid #eee;
        }
    }
}

.footer-2 {
    display: none;
}

.footer-3 {
    background-color: #f5f5f5;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    .records {
        display: flex;
        align-items: center;
        margin: 8px 0 0;
        font-size: 12px;
        color: #666;
        white-space: nowrap;
    }

}

@media screen and (max-width: 568px) {
    .header {
        display: block;
        height: 12vw !important;
        left: 0 !important;
        transform: none !important;
    }

    .tab {
        min-width: 100% !important;
        height: 12vw !important;
        padding: 0 5vw !important;

        .tab-left {
            .logo {
                width: 30vw !important;
            }

            .tab-outer {
                display: none !important;
            }
        }

        .btn-outer {

            .btn {
                display: none !important;
            }

            .more {
                display: block !important;
            }
        }
    }

    .footer-1 {
        display: none !important
    }

    .footer-2 {
        display: block !important;

        a {
            color: rgba(0, 0, 0, 0.85) !important;
        }
    }

    .footer-3 {
        background: #fff !important;
        margin-top: 3.5vw !important;
        margin-bottom: 3.5vw !important;
        height: 15vw !important;
        padding: 0 5vw !important;

        .records {
            font-size: 3.2vw !important;
            display: block !important;
            white-space: break-spaces !important;
            a{
                display:inline !important;
            }
        }
    }
}
</style>
<style>
body {
    text-align: center;
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Microsoft YaHei", Tahoma, Arial;
}

@media screen and (max-width: 568px) {
    .ant-layout-content {
        margin-top: 12vw !important;
    }

    .ant-collapse {
        font-size: 3.7vw !important;
    }

    .ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
        font-size: 3.2vw !important;
    }

    .ant-collapse-content>.ant-collapse-content-box {
        padding: 0 4.8vw 0 !important;
    }
}
</style>