//基础路由
import BasicLayout from '@/layouts/BasicLayout';
export const constantRouterMap = [
    {
        path: '/', 
        redirect: '/',
        component: BasicLayout,
        children: [
            {
                path: '/',
                meta:{
                    title:'首页'
                },
                component: () => import('@/pages/index/Index')
            },
            {
                path: '/vip',
                meta:{
                    title:'会员'
                },
                component: () => import('@/pages/vip/Index')
            },
            {
                path: '/download',
                meta:{
                    title:'下载'
                },
                component: () => import('@/pages/download/Index')
            },
            {
                path: '/about',
                meta:{
                    title:'关于我们'
                },
                component: () => import('@/pages/about/Index')
            },
            {
                path: '/contact',
                meta:{
                    title:'联系我们'
                },
                component: () => import('@/pages/contact/Index')
            },
        ]
    },
    {
        path: '/result',
        meta:{
            title:'结果页'
        },
        component: () => import('@/pages/result/Index')
    },
    {
        path: '/service_agreement',
        meta:{
            title:'服务协议'
        },
        component: () => import('@/pages/agreement/ServiceAgreement')
    },
    {
        path: '/privacy_agreement',
        meta:{
            title:'隐私政策'
        },
        component: () => import('@/pages/agreement/PrivacyAgreement')
    },
    {
        path: '/pay_agreement',
        meta:{
            title:'付费服务协议'
        },
        component: () => import('@/pages/agreement/PayAgreement')
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/404',
        children: [
            {
                path: '/404',
                component: () => import( '@/pages/404')
            }
        ]
    },
    
];